import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { Container } from 'react-bootstrap';
import commentBox from 'commentbox.io';

import Layout from '../components/Layout';
import SEO from '../components/Seo';

import styles from '../css/single-blog.module.css';

export const query = graphql`
  query($slug: String!) {
    blogPost: contentfulBlogPost(slug: { eq: $slug }) {
      title
      createdAt(formatString: "DD/MM/YYYY")
      author {
        name
      }
      description {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;

const BlogTemplate = ({ data }) => {
  const { title, createdAt, author, description } = data.blogPost;

  useEffect(() => {
    const removeCommentBox = commentBox(
      process.env.GATSBY_COMMENTBOX_PROJECT_ID,
    );
    return () => {
      removeCommentBox();
    };
  }, []);

  return (
    <Layout pageInfo={{ pageName: 'blog' }}>
      <SEO title={`Blog - ${title}`} />
      <Container>
        <h1>{title}</h1>
        <p>
          {createdAt} - {author.name}
        </p>
        <div
          className={styles.blogpost}
          dangerouslySetInnerHTML={{
            __html: description.childMarkdownRemark.html,
          }}
        ></div>
        <div className="commentbox" />
      </Container>
    </Layout>
  );
};

export default BlogTemplate;
